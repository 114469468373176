import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';
import { FONTS } from '../../../styles/fonts';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    padding: 16,
    background: COLORS.jjdRed,
    paddingBottom: 112,
    [QUERIES.mobile]: {
      paddingBottom: 64
    }
  },
  content: {
    maxWidth: 1200,
    margin: '0 auto'
  },
  h1: {
    color: COLORS.white,
    textAlign: 'center',
    maxWidth: 900,
    margin: '0 auto',
    fontSize: 64,
    fontFamily: FONTS.Content,
    lineHeight: '64px',
    paddingTop: 64,
    [QUERIES.mobile]: {
      paddingTop: 32,
      fontSize: 32,
      lineHeight: '48px'
    }
  },
  description: {
    color: COLORS.jjdGreyish,
    fontWeight: '700',
    fontFamily: FONTS.Content,
    textAlign: 'center',
    maxWidth: 1000,
    margin: '0 auto',
    paddingTop: 64
  }
});

export default styles;
