import React from 'react';
import AffordableIcon from '@material-ui/icons/SwapCalls';
import EasyIcon from '@material-ui/icons/Weekend';
import TrustedIcon from '@material-ui/icons/LocationCity';
import styles from './CoreValues.styles';
import { css } from 'aphrodite';
import STYLES from '../../../styles/shared';
import COLORS from '../../../styles/colors';

const CoreValues = () => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>

      <div className={css(STYLES.column)} style={{ paddingTop: 64, paddingBottom: 64 }}>
        <div className={css(styles.mainFeature)}>
          <div className={css(styles.mainFeatureImgContainer)}>
            <img alt={'Courier delivery app'} className={css(styles.mainFeatureImg, STYLES.cardBoxShadow)} src={require('../../../assets/team/team.jpg')} />
          </div>
          <div className={css(styles.mainFeatureDescContainer)}>
            <h1 style={{ color: COLORS.offBlack, fontWeight: 600 }}>We make things easy for you on moving day</h1>
            <p style={{ paddingBottom: 32, color: COLORS.jjdGreyish }}>We're dedicated to continuous improvement in our development cycle. Read our focal points below</p>
          </div>
        </div>
      </div>

      <div className={css(styles.moreFeatures)}>
        <div className={css(styles.featureContainer)}>
          <AffordableIcon className={css(styles.icon)} />
          <h2 className={css(styles.h2)}>Transparency</h2>
          <p className={css(styles.p)}>We're upfront about prices. We pay drivers at market rate, and take a 5% to 20% commission</p>
        </div>
        <div className={css(styles.featureContainer)}>
          <EasyIcon className={css(styles.icon)} />
          <h2 className={css(styles.h2)}>Availability</h2>
          <p className={css(styles.p)}>Our aim is to guarantee available drivers 24/7 across the whole of the UK and Europe within 1 hour</p>
        </div>
        <div className={css(styles.featureContainer)}>
          <TrustedIcon className={css(styles.icon)} />
          <h2 className={css(styles.h2)}>Simplicity</h2>
          <p className={css(styles.p)}>Everything we design and build is geared towards a simple, easy-to-use experience</p>
        </div>
      </div>
    </div>
  </div>
);

export default CoreValues;
