import React from 'react';
import DownloadFree from '../containers/sections/DownloadFree/DownloadFree';
import Layout from '../containers/gatsby/layout';
import AboutUsHero from '../containers/heroes/AboutUsHero/AboutUsHero';
import CoreValues from '../containers/sections/CoreValues/CoreValues';

const AboutPage = (props) => (
  <Layout location={props.location}>
    <AboutUsHero/>
    <CoreValues/>
    <DownloadFree/>
  </Layout>
);

export default AboutPage;
