import React from 'react';
import styles from './AboutUsHero.styles';
import { css } from 'aphrodite';

const AboutUsHero = (props) => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>
      <h1 className={css(styles.h1)}>
        We're building the world's most reliable On-Demand man and van network
      </h1>
      <p className={css(styles.description)}>
        JJD is a transport company that is bringing the man and van industry into the 21st century. We connect affordable and professional drivers to customers within minutes, tailored to their needs. We aim to remove all the haggling, spam, and inefficiency that exists in removals services today.
      </p>
    </div>
  </div>
);

export default AboutUsHero;
